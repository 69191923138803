<template>
  <b-modal
    id="modal-sale-add"
    v-model="open"
    hide-footer
    centered
    size="md"
    static
    lazy
    no-close-on-esc
    hide-header-close
    :no-close-on-backdrop="true"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      class="d-flex flex-column"
      variant="transparent"
      style="flex: 1"
    >
      <b-container class="tw-p-8">
        <h1 class="text-center tw-mb-6">
          {{ $t(title) }}
        </h1>
        <feather-icon
          class="cursor-pointer position-absolute"
          style="top: 0; right: 1rem"
          icon="XIcon"
          size="22"
          @click="close"
        />
        <b-form>
          <b-form-group
            v-if="uuid_key"
            :label="$t('Cause')"
            label-for="cause"
          >
            <v-select
              id="cause"
              v-model="cause"
              :placeholder="$t('Select Cause')"
              :options="causes"
            />
            <b-form-invalid-feedback v-if="errors.cause">{{ $t(errors.cause) }}</b-form-invalid-feedback>
          </b-form-group>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Product')"
                label-for="product-select"
              >
                <v-select
                  id="product-select"
                  v-model="product"
                  :options="products"
                  :get-option-label="option => option.name"
                  :reduce="option => option.code"
                  label="name"
                  :placeholder="$t('Select Product')"
                >
                  <template slot="no-options">
                    {{ $t('Sorry, no matching options') }}
                  </template>
                </v-select>
                <b-form-invalid-feedback v-if="errors.product">{{ $t(errors.product) }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Coverage')"
                label-for="coverage"
              >
                <v-select
                  id="coverage"
                  v-model="coverage"
                  :placeholder="$t('Select Coverage')"
                  :options="coverages"
                />
                <b-form-invalid-feedback v-if="errors.coverage">{{ $t(errors.coverage) }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Customer Type')"
                label-for="customer-type"
              >
                <v-select
                  id="customer-type"
                  v-model="existingCustomer"
                  :options="customerTypes"
                  :get-option-label="option => option.name"
                  :reduce="option => option.id"
                  label="name"
                  :placeholder="$t('Select Customer Type')"
                />
                <b-form-invalid-feedback v-if="errors.existingCustomer">{{ $t(errors.existingCustomer) }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('Car Brand')"
                label-for="car-brand"
              >
                <b-form-input
                  id="car-brand"
                  v-model="brand"
                  :placeholder="$t('Car Brand')"
                />
                <b-form-invalid-feedback v-if="errors.brand">{{ $t(errors.brand) }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('Car Model')"
                label-for="car-model"
              >
                <b-form-input
                  id="car-model"
                  v-model="model"
                  type="text"
                  :placeholder="$t('Car Model')"
                />
                <b-form-invalid-feedback v-if="errors.model">{{ $t(errors.model) }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('Chassis Number')"
                label-for="chassis-number"
              >
                <b-form-input
                  id="chassis-number"
                  v-model="chassisNumber"
                  type="text"
                  :placeholder="$t('Chassis Number')"
                />
                <b-form-invalid-feedback v-if="errors.chassisNumber">{{ $t(errors.chassisNumber) }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                :label="$t('Car Condition')"
                label-for="car-condition"
              >
                <v-select
                  id="car-condition"
                  v-model="newCar"
                  :options="carConditions"
                  :placeholder="$t('Select Car Condition')"
                  :get-option-label="option => option.name"
                  :reduce="option => option.id"
                  label="name"
                />
                <b-form-invalid-feedback v-if="errors.newCar">{{ $t(errors.newCar) }}</b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group
            :label="$t('Sale Date')"
            label-for="sale-date"
          >
            <date-picker
              id="ends-at"
              v-model="date"
              value-type="DD-MM-YYYY"
              format="DD-MM-YYYY"
              type="date"
              :placeholder="$t('Select Date')"
              class="w-100"
            ></date-picker>
            <b-form-invalid-feedback v-if="errors.date">{{ $t(errors.date) }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label="$t('Agent')"
            label-for="seller"
          >
            <v-select
              id="seller"
              v-model="contactUuid"
              :disabled="!uuid_key"
              :placeholder="$t('Agent')"
              :options="contacts"
              label="fullName"
              :reduce="contact => contact.uuid"
              @search="onSearch"
            >
            </v-select>
            <b-form-invalid-feedback v-if="errors.contactUuid">{{ $t(errors.contactUuid) }}</b-form-invalid-feedback>
          </b-form-group>
        </b-form>
        <div v-if="errorMsg != null" style="text-align: center;">
          <p style="color: red;">{{ $t(errorMsg) }}</p>
        </div>
        <div class="d-flex justify-content-end align-items-center tw-mt-6">
          <button
            class="btn btn-light tw-ml-3"
            type="button"
            @click="close"
          >
            {{ $t('Cancel') }}
          </button>
          <button
            type="button"
            class="btn btn-secondary tw-ml-3"
            @click="handleSave"
          >
            {{ $t('Save') }}
          </button>
        </div>
      </b-container>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import RootEvents from '@/constants/rootEvents'
import GlobalToastNotification from '@/components/shared/GlobalToastNotification.vue'
import { customEntityRowsService } from '@/services/customEntityData'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import contactService from '@/services/contact'
import salesService from '@/services/sales'
import 'vue2-datepicker/index.css'
import { debounce } from 'lodash'
import * as Yup from 'yup'
import moment from 'moment'

export default {
  components: {
    vSelect,
    DatePicker,
  },
  props: {
    shouldAddUser: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      causes: ['Angrerett', 'Avvik', 'Bestilt dobbelt', 'Kjøp og salg', 'Feil selskap', 'Feil bilselger', 'Selgerfeil', 'Annet'],
      products: [],
      coverages: ['Kasko', 'Delkasko', 'Annet'],
      customerTypes: [
        { id: 1, name: 'Ny' },
        { id: 0, name: 'Eksisterende' },
      ],
      carConditions: [
        { id: 1, name: 'Ny' },
        { id: 0, name: 'Brukt' },
      ],
      errorMsg: null,
      contacts: [],
      uuid_key: null,
      title: 'Add Sale',
      month: null,
      week: null,
      date: null,
      product: null,
      coverage: null,
      brand: null,
      model: null,
      chassisNumber: null,
      contactUuid: null,
      newCar: null,
      existingCustomer: null,
      cause: null,
      open: false,
      loading: false,
      errors: [],
    }
  },

  computed: {
    ...mapGetters({
      searchModel: 'advancedSearch/searchModel',
    }),

    isEditMode() {
      return !!this.$route.params.id
    },

    canSavePersonal() {
      return this.$can('Create', 'Personal_Query')
    },
  },

  mounted() {
    console.log(this.shouldAddUser)
  },

  destroyed() {
    this.$root.$off(RootEvents.ADV_SEARCH_CONFIRM_SAVE)
  },

  methods: {
    close() {
      this.title = 'Add Sale'
      this.open = false
      this.loading = false
      this.errors = []
      this.version = null
      this.uuid_key = null
      this.date = null
      this.week = null
      this.month = null
      this.product = null
      this.coverage = null
      this.brand = null
      this.model = null
      this.chassisNumber = null
      this.contactUuid = null
      this.newCar = null
      this.existingCustomer = null
      this.cause = null
    },
    callToast(message) {
      this.$toast(
        {
          component: GlobalToastNotification,
          props: {
            message,
          },
        },
        {
          closeButton: false,
          timeout: 7000,
          containerClassName: 'toast-notification',
        },
      )
    },
    async handleSave() {
      if (!this.$route.params.tableName) {
        return
      }

      const schema = Yup.object().shape({
        // Define validation rules for each field
        cause: Yup.string()
          .nullable()
          .when('uuid_key', {
            is: val => !!val,
            then: Yup.string().nullable().required('Required'),
          }),
        product: Yup.string().nullable().required('Required'),
        coverage: Yup.string().nullable().required('Rrequired'),
        existingCustomer: Yup.string().nullable().required('Required'),
        brand: Yup.string().nullable().required('Required'),
        model: Yup.string().nullable().required('Required'),
        chassisNumber: Yup.string().nullable().required('Required'),
        newCar: Yup.string().nullable().required('Required'),
        date: Yup.string().nullable().required('Required'),
        contactUuid: Yup.string().nullable().required('Required'),
      })

      try {
        this.errorMsg = null
        await schema.validate(
          {
            cause: this.cause,
            product: this.product,
            coverage: this.coverage,
            existingCustomer: this.existingCustomer,
            brand: this.brand,
            model: this.model,
            chassisNumber: this.chassisNumber,
            newCar: this.newCar,
            date: this.date,
            contactUuid: this.contactUuid,
          },
          { abortEarly: false },
        )

        const payload = {
          month: moment(this.date, 'DD-MM-YYYY').month(),
          week: moment(this.date, 'DD-MM-YYYY').isoWeek(),
          date: moment(this.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          product: this.product,
          coverage: this.coverage,
          brand: this.brand,
          model: this.model,
          chassisNumber: this.chassisNumber,
          contactUuid: this.contactUuid,
          newCar: this.newCar,
          ...(this.uuid_key && {
            existingCustomer: this.existingCustomer,
            cause: this.cause,
          }),
        }

        this.loading = true
        this.uuid_key
          ? await salesService.updateSale(this.$route.params.tableName, this.uuid_key, this.version, payload)
          : await salesService.addSale(this.$route.params.tableName, payload)

        this.callToast({ subject: this.title, message: this.uuid_key ? 'Sale updated successfully' : 'Sale added successfully' })
        this.$emit('onSave')
        this.close()
      } catch (error) {
        // Handle validation errors
        if (error instanceof Yup.ValidationError) {
          const fieldErrors = {}
          error.inner.forEach(e => {
            fieldErrors[e.path] = e.message
          })
          this.errors = fieldErrors
          this.errorMsg = null
        } else if (error.response != null && error.response.data != null && error.response.data.message != null) {
          this.errorMsg = error.response.data.message
        } else {
          this.errorMsg = null
        }
      } finally {
        this.loading = false
      }
    },
    async getProducts() {
      this.loading = true
      try {
        const { data } = await customEntityRowsService('model_product').getAll()
        if (data) {
          this.products = data.pageItems.map(item => item.values)
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    search: debounce(async (loading, search, vm) => {
      if (search.trim() === '') return

      try {
        const { data } = await contactService.autocomplete(search.trim())
        if (data) {
          if (data) {
            vm.contacts = data.map(item => ({
              ...item,
              fullName: `${item.firstName || ''} ${item.lastName || ''}`,
            }))
          } else {
            vm.contacts = []
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        loading(false)
      }
    }, 350),

    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.search(loading, search, this)
      }
    },

    async openModal({ item }) {
      this.open = true
      await this.getProducts()
      if (this.$route.params?.name && this.$route.params?.contactId) {
        this.contacts = [
          {
            uuid: this.$route.params?.contactId,
            fullName: this.$route.params?.name,
          },
        ]
        this.contactUuid = this.$route.params.contactId
      }

      if (item) {
        this.title = item ? 'Edit Sale' : 'Add Sale'
        this.date = moment(item.date, 'DD-MM-YYYY').format('DD-MM-YYYY')
        this.version = item.version
        this.uuid_key = item.uuid_key
        this.week = item.week
        this.month = item.month
        this.product = item.product
        this.coverage = item.hull_coverage
        this.brand = item.car_brand
        this.model = item.car_model
        this.chassisNumber = item.chassis_number
        this.newCar = item.new_car
        this.existingCustomer = item.existing_customer
        this.cause = item.cause || 'Angrerett'
        if (item.seller && item.contact_uuid) {
          this.contactUuid = item.contact_uuid || ''
          this.contacts = [{ uuid: item.contact_uuid, fullName: item.seller }]
        }
      }
    },
  },
}
</script>
