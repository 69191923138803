<template>
  <b-card>
    <div>
      <b-row
        align-h="between"
        align-v="baseline"
      >
        <b-col
          cols="12"
          lg="6"
        >
          <div class="d-flex row-flex-align-start">
            <prozess-search-input
              class="tw-mr-4"
              :value="search"
              :placeholder="$t('Search')"
              @input="handleSearch"
            />
          </div>
        </b-col>
      </b-row>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        rounded="sm"
        variant="transparent"
        style="flex: 1"
        class="d-flex flex-column mt-2"
      >
        <b-table
          ref="refTable"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t('No matching records found')"
          class="table-responsive"
          no-local-sorting
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          @sort-changed="handleSortChange"
        >
          <template #head()="{ label }">
            <span style="white-space: nowrap">{{ $t(label) }}</span>
          </template>

          <template #cell(mobileNumber)="{ item }">
            <span class="text-nowrap text-primary">
              {{ item.mobileNumber }}
            </span>
          </template>

          <template #cell(statusName)="{ item }">
            <span>{{ item.statusName }}</span>
          </template>

          <!-- START: for customizable fields -->

          <template #cell()="data">
            <router-link
              v-if="isTitle(data)"
              class="text-nowrap text-primary"
              :to="{
                name: 'contact-view',
                params: {
                  idFeed: data.item.id,
                  id: data.item.uuid,
                  tab: GenericTabs.OVERVIEW_360,
                },
              }"
            >
              {{ findValue(data) }}
            </router-link>
            <span
              v-else
              :id="`tooltip-${data.index}-${data.field.key}`"
              style="max-width: 200px"
              class="d-block"
              :class="{ 'text-primary': data.field.key.includes('mobile'), 'text-truncate': data.field.type === 'LONGTEXT' }"
            >
              {{ findValue(data) }}
            </span>
            <b-tooltip
              v-if="data.field.type === 'LONGTEXT'"
              :target="`tooltip-${data.index}-${data.field.key}`"
              :title="findValue(data)"
              placement="down"
            />
          </template>
          <!-- END: for customizable fields -->
        </b-table>
      </b-overlay>

      <prozess-pagination
        v-if="!loading"
        class="mx-2 mb-2"
        :class="{ 'd-none': !total }"
        :meta="dataMeta"
        :page="currentPage"
        :total="total"
        :size="perPage"
        @change="handleCurrentPage"
      />
    </div>
  </b-card>
</template>

<script>
import FilterInput from '@/components/Lists/FilterInput.vue'
import { Schema, Entity, GenericTabs } from '@/constants/app'
import salesService from '@/services/sales'
import ImexActionsDropdown from '@/views/shared/Imex/ImexActionsDropdown.vue'
import listMixinFactory from '@/mixins/list'
import SidebarForm from '@/components/Contact/ContactForm.vue'
import metastatusDropdownMixin from '@/mixins/metastatusDropdown'
import StatusFilter from '@/components/Lists/StatusFilter.vue'
import DateRangeFilter from '@/components/Lists/DateRangeFilter.vue'
import UserGroupFilter from '@/components/Lists/UserGroupFilter.vue'
import AddSaleModal from '../AddSaleModal.vue'

export default {
  components: {
    SidebarForm,
    ImexActionsDropdown,
    FilterInput,
    StatusFilter,
    DateRangeFilter,
    UserGroupFilter,
    AddSaleModal,
  },
  mixins: [
    metastatusDropdownMixin,
    listMixinFactory({
      routeName: 'sales-contacts',
      service: salesService,
      listFormat: true,
      isCustomService: false,
      isCustomizable: true,
      searchField: 'firstName',
      sort: {
        name: 'firstName',
      },
      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'emailAddress', label: 'Email', class: 'disabled' },
        { key: 'mobileNumber', label: 'Mobile', class: 'disabled' },
        { key: 'title', label: 'Job Title', class: 'text-center disabled' },
        {
          key: 'numberOfCompanies',
          label: 'Companies',
          class: 'text-center disabled',
        },
        { key: 'actions', class: 'text-center disabled' },
      ],
    }),
  ],
  data() {
    return {
      GenericTabs,
      Schema,
      Entity,
    }
  },

  mounted() {
    this.getMetastatusInitialData()
  },
}
</script>
