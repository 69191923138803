var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',[_c('div',[_c('b-row',{attrs:{"align-h":"between","align-v":"baseline"}},[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('div',{staticClass:"d-flex row-flex-align-start"},[_c('prozess-search-input',{staticClass:"tw-mr-4",attrs:{"value":_vm.search,"placeholder":_vm.$t('Search')},on:{"input":_vm.handleSearch}})],1)])],1),_c('b-overlay',{staticClass:"d-flex flex-column mt-2",staticStyle:{"flex":"1"},attrs:{"show":_vm.loading,"spinner-variant":"primary","rounded":"sm","variant":"transparent"}},[_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":_vm.$t('No matching records found'),"no-local-sorting":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"sort-changed":_vm.handleSortChange},scopedSlots:_vm._u([{key:"head()",fn:function({ label }){return [_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(mobileNumber)",fn:function({ item }){return [_c('span',{staticClass:"text-nowrap text-primary"},[_vm._v(" "+_vm._s(item.mobileNumber)+" ")])]}},{key:"cell(statusName)",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.statusName))])]}},{key:"cell()",fn:function(data){return [(_vm.isTitle(data))?_c('router-link',{staticClass:"text-nowrap text-primary",attrs:{"to":{
              name: 'contact-view',
              params: {
                idFeed: data.item.id,
                id: data.item.uuid,
                tab: _vm.GenericTabs.OVERVIEW_360,
              },
            }}},[_vm._v(" "+_vm._s(_vm.findValue(data))+" ")]):_c('span',{staticClass:"d-block",class:{ 'text-primary': data.field.key.includes('mobile'), 'text-truncate': data.field.type === 'LONGTEXT' },staticStyle:{"max-width":"200px"},attrs:{"id":`tooltip-${data.index}-${data.field.key}`}},[_vm._v(" "+_vm._s(_vm.findValue(data))+" ")]),(data.field.type === 'LONGTEXT')?_c('b-tooltip',{attrs:{"target":`tooltip-${data.index}-${data.field.key}`,"title":_vm.findValue(data),"placement":"down"}}):_vm._e()]}}])})],1),(!_vm.loading)?_c('prozess-pagination',{staticClass:"mx-2 mb-2",class:{ 'd-none': !_vm.total },attrs:{"meta":_vm.dataMeta,"page":_vm.currentPage,"total":_vm.total,"size":_vm.perPage},on:{"change":_vm.handleCurrentPage}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }