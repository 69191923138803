<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    :editing="!!resourceId"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    @close="close"
    @submit="save"
  >
    <form
      id="formSidebar"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <span v-if="$hasError('statusId')" class="invalid-feedback">
        {{ $t($hasError('statusId')) }}
      </span>
      <div v-for="field in fieldMetadata" :key="field.key">
        <AppDynamicField
          v-model="form[field.key]"
          :field="field"
          :error="$hasError(field.key)"
          :resourceId="resourceId"
        />
      </div>
      <prozess-field-wrapper
        :icon="MetastatusTypeIcons['status']"
        :error="$hasError('statusId')"
      >
        <prozess-select
          v-model="form.statusId"
          style="flex: 1"
          :placeholder="$t('Status')"
          :options="statusOptions"
          :reduce="(option) => option.metaStatusId"
          label="metaStatusName"
          name="statusId"
          field="statusId"
          :clearable="false"
        />
      </prozess-field-wrapper>

      <form-notification
        :id="resourceId"
        :visible="visible"
        @userAdded="handleSelectedUser"
        @userRemoved="handleUserRemoved"
        @groupAdded="handleSelectedGroup"
        @groupRemoved="handleGroupRemoved"
      />
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { MetastatusTypeIcons } from '@/constants/metastatusTypes'
import { renameKeys } from 'lodash-contrib'
import { Entity, Schema } from '@/constants/app'
import { contactSchema } from '@/schema/contact'
import { advancedFieldValue } from '@/helpers/customFields'
import { fillableOnly } from '@/helpers/field'
import { formatAsPayload, standardKeyMapping } from '@/helpers/contactForm'
import { fetchBackendErrors } from '@/helpers/backendValidations'
import advancedFieldMgmtService from '@/services/advancedFieldMgmt'
import contactService from '@/services/contact'
import AppDynamicField from '@/components/shared/AppDynamicField.vue'
import CustomFieldTypes from '@/constants/customFieldTypes'
import FormNotification from '@/components/Forms/FormNotification.vue'

const staticField = {
  statusId: 'statusId',
}

export default {
  components: {
    AppDynamicField,
    FormNotification,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      MetastatusTypeIcons,
      CustomFieldTypes,
      errors: [],
      form: {},
      fieldMetadata: [],
      groupList: [],
      userList: [],
      loading: true,
      saving: false,
    }
  },

  computed: {
    formTitle() {
      return this.resourceId ? 'Edit Contact' : 'Add New Contact'
    },
  },

  watch: {
    visible: {
      async handler(visible) {
        if (visible) {
          this.reset()
          await this.getAllFields()
          if (this.resourceId) {
            this.getData()
          }
        }
      },

      immediate: true,
    },

    statusOptions: {
      handler(value) {
        if (value) this.setStatus()
      },
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },

    handleSelectedUser(userUuid) {
      this.userList.push(userUuid)
    },

    handleSelectedGroup(groupUuid) {
      this.groupList.push(groupUuid)
    },

    handleGroupRemoved(groupUuid) {
      this.groupList = this.groupList.filter(
        group => group !== groupUuid,
      )
    },

    handleUserRemoved(userUuid) {
      this.userList = this.userList.filter(
        user => user !== userUuid,
      )
    },

    formatAsFormData(response) {
      const customFields = response.customFieldData.reduce((acc, item) => {
        acc[item.key] = advancedFieldValue(item.value, {
          toArrayIfMulti: true,
        })
        return acc
      }, {})

      const form = this.$lodash.pick(response, Object.keys(standardKeyMapping))

      this.form = {
        ...renameKeys(form, standardKeyMapping),
        ...customFields,
      }
    },
    async getAllFields() {
      const { response } = await this.$async(
        advancedFieldMgmtService.getFieldsByEntity({
          schema: Schema.CRM,
          table: Entity.CONTACT,
        }),
      )

      const fieldMetadata = fillableOnly(response.data)
      this.filteredDuplicatedField = fieldMetadata.filter(
        fieldFilter => !Object.values(staticField).some(val => val === fieldFilter.key),
      )
      // Force $set all fields to `form` object to make them reactive for v-models
      fieldMetadata.forEach(field => {
        if (field.type === 'BOOL') {
          this.$set(this.form, field.key, false)
        } else this.$set(this.form, field.key, null)
      })

      const standardFields = fieldMetadata.filter(i => i.id == null)
      const customFields = fieldMetadata.filter(i => i.id != null)
      const sortedFields = customFields.concat(standardFields)

      this.fieldMetadata = this.$lodash.cloneDeep(sortedFields)
      this.setStatus()
    },
    setStatus() {
      if (this.resourceId) return
      const statusDefaultValue = this.statusOptions.find(status => status.defaultTo.includes('contact'))
      this.form.statusId = statusDefaultValue?.metaStatusId ?? ''
    },
    async getData() {
      this.loading = true

      const { response } = await this.$async(
        contactService.getOne(this.resourceId),
      )

      this.formatAsFormData(response.data)

      this.loading = false
    },

    reset() {
      this.form = {}
      this.errors = []
      this.userList = []
      this.groupList = []
      this.loading = false
      this.saving = false
    },

    async save() {
      const statusValidation = [
        {
          required: true,
          key: 'statusId',
          type: 'TEXT',
        },
      ]
      this.form.last_name = this.form.last_name?.trim()

      const form = this.$lodash.cloneDeep(this.form)

      if (form.mobile_number?.startsWith('+47')) {
        form.mobile_number = form.mobile_number.substring(3).trim()
      }

      this.errors = await this.yupValidate(
        contactSchema([...this.fieldMetadata, statusValidation]),
        form,
      )

      if (this.errors.length) {
        return
      }

      this.saving = true

      let payload = formatAsPayload(this.form, this.fieldMetadata)

      if (payload.statusId === 0) {
        delete payload.statusId
      }

      if (this.userList) {
        payload = { ...payload, userUuidList: this.userList }
      }

      if (this.groupList) {
        payload = { ...payload, groupUuidList: this.groupList }
      }

      const request = this.resourceId
        ? contactService.update(this.resourceId, payload)
        : contactService.store({
          ...payload,
          contactVersion: 1,
        })

      const { error } = await this.$async(request)

      if (error) {
        this.saving = false
        const errorMessage = fetchBackendErrors(error, this.resourceId)
        if (errorMessage) return this.showWarning(errorMessage)
      }

      this.saving = false

      if (!error) {
        this.$emit('saved')
        this.close()
      }
    },
  },
}
</script>
